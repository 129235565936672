class CsvImportService {
  lastError = ''
  lastErrorCode = ''
  activeSearchRequest = null

  constructor(axios) {
      this.axios = axios
  }
  
  async getDocImportId() {
    return await this
      .axios
      .post('/api/import')
      .catch(res => {
        this.#handleError(res)
      })
  }

  async getTransactions(docImportId) {
    return await this
      .axios
      .get(`/api/import/${docImportId}`)
      .catch(res => {
        this.#handleError(res)
      })
  }

  async postFiles(files, docImportId, uploadProgressClbk) {
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: function (progressEvent) {
        uploadProgressClbk(progressEvent.loaded)
      }
    }
    let formData = new FormData();
    files.forEach(file => {
        formData.append('files[]', file)
    })
    
    return await this
      .axios
      .post(
        `/api/import/${docImportId}/files`,
        formData,
        options
      )
      .catch(res => {
        this.#handleError(res)
      })
  }

  async submitForReview(filename) {
    return await this
      .axios
      .post(`/api/import/file/submitForReview?filename=${filename}`)
      .catch(res => {
        this.#handleError(res)
      })
  }
  async query(endpoint) {
    return await this
      .axios
      .get(`/api/${endpoint}`)
      .then(response => response.data)
      .catch(res => {
        this.#handleError(res)
      })
  }

  async getBrokers() {
    return this.query('broker')
  }

  async getMics() {
    return this.query('mic')
  }

  async getCurrencies() {
    return this.query('currency')
  }

  async searchShares(term) {
    if (this.activeSearchRequest) {
        this.activeSearchRequest.cancel()
    }
    if (term === null) {
        term = '%'
    }

    const axiosSource = this.axios.CancelToken.source();
    this.activeSearchRequest = { cancel: axiosSource.cancel };

    const url = '/api/shares/facet-search'
    let data = {
        params: { q: term },
        cancelToken: axiosSource.token,
    }

    return await this
        .axios
        .get(url, data)
        .then(response => response.data)
        .catch(error => {
            if (this.axios.isCancel(error)) {
                return
            }
            throw error
        })
  }

    async commitImport(data, portfolioId, docImportId, progressCallback) {
        const chunkSize = 100;
        let hasErrors = false;
        let completedCommits = 0;
        for (let i = 0; i < data.length; i += chunkSize) {
            const chunk = data.slice(i, i + chunkSize);
            await this.axios
                .post(`/api/import/${docImportId}/write/${portfolioId}`, chunk)
                .then(()=>{
                    completedCommits += chunk.length;
                    if(progressCallback){
                        progressCallback(completedCommits)
                    }
                })
                .catch(e => {
                    hasErrors = true
                    this.lastErrorCode = e.response?.status || e.code
                    this.lastError =
                        this.lastError + "\n" +
                        e.response?.data?.message || e.message
                });
        }
        if (hasErrors) {
            let errorMessage = this.lastError
            this.lastError = '';
            throw new Error(errorMessage);
        }
    }

  async finalizeImport(portfolioId) {

    await this.axios
        .post(`/api/import/finalize/${portfolioId}`)
        .catch(res => {
          this.#handleError(res)
        });
  }

  #handleError(e) {
    console.log(e)

    this.lastErrorCode = e.response?.status || e.code
    this.lastError = e.response?.statusText || e.message

    throw e
  }
}

export default CsvImportService
